import React from 'react';

import Layout from "../layouts/site/layout";

function Overview() {
  return (
    <div className="App">
       <Layout>
            <div className="px-4 pt-7 pb-14">
                <h2 className="text-red-1000 font-bold text-base">Company overview</h2>
                <p className="mt-2">We specialise in the supply of critically demanding products with full traceability meeting the ever increasing requirements of our customers.</p>
                <p className="mt-2">We are committed to the needs of our customers. Working together in a relationship of trust and honesty to provide you with forging solutions. With our decades of experience and knowledge in forging steels ranging from carbon, low alloy, stainless, duplex, super duplex and super alloys.</p>
                <p className="mt-2">Through strategic investment and continuous improvement we offer short lead times with consistent on- time delivery in full for all of your critically demanding applications.</p>
                <h3 className="text-[15px] text-center font-bold mt-3">Forging Solutions Transforming Business</h3>
            </div>
       </Layout>
    </div>
  );
}

export default Overview;
